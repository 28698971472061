<template>
    <div class="container-fluid footer-end">
        <div class="container">
            <div class="row footer-box">
                <div class="col-md-2">
                    <img src="/assets/img/logo-rodape.png">
                </div>
                <div class="col-md-2">
                    <p class="footer-cnpj">Ektos Projetos Ltda<br>CNPJ: 23.499.792/0001-96</p>
                </div>

                <div class="col-md-4 col-md-offset-4">
                    <p class="footer-sign">Ticket Experience {{ (new Date()).getFullYear() }} - Todos os direitos
                        reservados.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "FooterEnd",
});
</script>