export default {
    name: "Carrinho",
    add_item(item) {
        let carrinho = window.localStorage.getItem('carrinho');
        if (carrinho == null) {
            let itens = [];
            itens.push(item);
            window.localStorage.setItem("carrinho",JSON.stringify(itens));
        }else{
            let itens = JSON.parse(carrinho);
            itens.push(item);
            window.localStorage.setItem("carrinho",JSON.stringify(itens));
        }

        window.dispatchEvent(new CustomEvent('user-carrinho'));
    },
    add_last_event(id,slug){
        let sl =`/escolha-o-kit/${id}/${slug}/`;
        window.localStorage.setItem("last_event",JSON.stringify(sl));
    },
    get_last_event(){
        let event = window.localStorage.getItem("last_event");
        return JSON.parse(event);
    },
    get_itens(){
        let carrinho = window.localStorage.getItem('carrinho');
        if (carrinho == null) {
            let itens = [];
            window.localStorage.setItem("carrinho",JSON.stringify(itens));
            return itens;
        }else{
            let itens = JSON.parse(carrinho);
            return itens;
        }
    },
    remove_item(index) {
        let carrinho = window.localStorage.getItem('carrinho');
        if (carrinho == null) {
            let itens = [];
            window.localStorage.setItem("carrinho",JSON.stringify(itens));
            window.dispatchEvent(new CustomEvent('user-carrinho'));
            return itens;
        }else{
            let itens = JSON.parse(carrinho);
            if(itens[index]){
                itens.splice(index,1);
                window.localStorage.setItem("carrinho",JSON.stringify(itens));
            }
            window.dispatchEvent(new CustomEvent('user-carrinho'));
            return itens;
        }

    },
    limpar(){
        let itens = [];
        window.localStorage.removeItem("carrinho");
        window.dispatchEvent(new CustomEvent('user-carrinho'));
        return itens;
    }
}
