<template>
    <div class="container-fluid footer">
        <div class="container footer-box">
            <div class="row">
                <div class="col-md-3 footer-social col-md-offset-3">
                    <p>
                        <a :href="FACEBOOK_LINK" target="_blank" class="mr-40"><img src="/assets/img/ico-face-wt.png"></a>
                        <a :href="INSTAGRAN_LINK" target="_blank" class="mr-40"><img src="/assets/img/ico-insta-wt.png"></a>
                        <a v-if="LINKEDIN_LINK != ''" :href="LINKEDIN_LINK" target="_blank" ><img src="/assets/img/ico-lkd-wt.png"></a>
                    </p>
                </div>
                <div class="col-md-5 col-md-offset-1">
                    <div class="bloco1">
                        <ul>
                            <li><v-link href="/conteudo/8/suporte-ao-organizador/">SUPORTE AO ORGANIZADOR</v-link></li>
                            <li><v-link href="/cadastre-seu-evento/">CADASTRE SEU EVENTO</v-link></li>
                        </ul>
                        <ul>
                            <li><v-link href="/conteudo/7/funcionalidades/">FUNCIONALIDADES</v-link></li>
                            <li><v-link href="/conteudo/9/faca-um-orcamento/">ORÇAMENTO</v-link></li>
                        </ul>
                    </div>
                    <div class="bloco2">
                        <ul>
                            <li><v-link href="/conteudo/1/sobre-a-ticket-experience/">A TICKET EXPERIENCE</v-link></li>
                            <li><v-link href="/conteudo/2/anuncie-conosco/">ANUNCIE CONOSCO</v-link></li>
                            <li><v-link href="/conteudo/3/termos-de-uso/">TERMOS DE USO</v-link></li>
                        </ul>
                        <ul>
                            <li><v-link href="/contato/">CONTATO</v-link></li>
                            <li><v-link href="/conteudo/5/central-de-ajuda/">CENTRAL DE AJUDA</v-link></li>
                            <li><v-link href="/conteudo/6/trabalhe-conosco/">TRABALHE CONOSCO</v-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VLink from "./VLink";
export default {
  name:'Footer',
  components:{VLink},
  data() {
    return {
      FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
      INSTAGRAN_LINK: process.env.VUE_APP_INSTAGRAN_LINK,
      LINKEDIN_LINK: process.env.VUE_APP_LINKEDIN_LINK,
    };
  }
}
</script>

<style>
.footer-social .mr-40 {
  margin-right: 43px;
}
</style>
