<template>
    <a :href="href" @click.prevent="go" :class="className" :title="title">
        <slot></slot>
    </a>
</template>

<script>
    export default {
        props: {
            href: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: false
            },
            className: {
                type: String,
                required: false,
            }
        },

        methods: {
            go() {
              this.$router.push(this.href)
              window.scrollTo(0,0);
            }
        }
    }
</script>